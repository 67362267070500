import { render, staticRenderFns } from "./introduces.vue?vue&type=template&id=19f4f239&scoped=true&"
import script from "./introduces.vue?vue&type=script&lang=js&"
export * from "./introduces.vue?vue&type=script&lang=js&"
import style0 from "./introduces.vue?vue&type=style&index=0&id=19f4f239&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f4f239",
  null
  
)

export default component.exports