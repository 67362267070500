<template>
  <div class="lianluo" style="height: 100%">
    <div class="content">
     <h3 class="title">吉利华阳产业集聚区概况</h3>
      <p>吉利华阳产业集聚区(洛阳国家高新区吉利华阳科技园区)位于洛阳市孟津区北部，跨黄河两岸南北分布，于2021年4月由原洛阳市石化产业集聚区(北岸园区)和孟津县华阳产业集聚区(南岸园区)整合成立。</p>
      <img src="../../assets/huayang/huayang2.png" alt="" class="imgs">
     <h4> 一、总体规划</h4>
     <p> 吉利华阳产业集聚区是河南省首批规划的180家省定产业集聚区之一，规划面积20.79平方公里，其中北岸园区12.97平方公里(含国家高新区1平方公里、郑洛新国家自主创新示范区核心区8.54平方公里、省级高新技术产业开发区11.47平方公里)
      ，南岸园区7.82平方公里。主导产业为石油化工、装备制造和新材料。</p>
      <h4>二、产业发展</h4>
      <p>目前,园区共有规模以上企业101家，初步形成了以洛阳石化、宏兴新能、洛阳炼化为龙头的石油化工产业集群，以洛北重工、百成内燃、金彭电动车为龙头的装备制造产业集群，以昊华气体、科博思新材、三杰新材为龙头的新材料产业集群。2020年完成营业收入682.3亿元，税收84.86亿元。2021年上半年完成营业收入352亿元，税收57.3亿元。先后被授予“河南省三星级产业集聚区”“河南省十强产业集聚区”河南省十快产业集聚区”等荣誉称号。</p>
      <h4>三、项目建设</h4>
      <p>近三年，一大批产业项目顺利推进。围绕炼油龙头，总投资45亿元的洛阳石化炼油结构调整项目主体中交，炼油能力达到1000万吨/年；总投资20亿元的原油商储库项目竣工投用，原油储备能力达160万方；总投资49亿元的日照—濮阳—洛阳原油管道工程进入收尾阶段,计划年底前全线建成；总投资12亿元的洛阳—新郑机场航煤管线项目正在办理核准手续，力争年内开工建设。围绕烯烃、芳烃产业，总投资297亿元的洛阳石化百万吨乙烯项目可研报告已编制完成，河南省政府和中石化集团已联合行文至国家发改委，正在积极列入国家石化产业布局方案；总投资105亿元的100万吨/年PX 和150万吨/年 PTA
      项目正在与中石化沟通对接建设方案，有望明年开工建设。围绕新材料产业，加快建设总投资62.5亿元的昊华电子特气、黎明院特种化学品和中硅电子信息材料等项目，进一步壮大新材料产业集群。围绕氢能等未来产业，推动实施总投资43亿元的华久氢能一体化、输氢管线、加氢站等项目。在这些重大项目支撑下，园区产业集聚效应更强，转型发展高质量发展后劲十足。
      2021年，深入贯彻落实省委省政府项目建设“三个一批”活动要求，突出抓好总投资147亿元的33个重点项目，其中“签约一批”项目12个，总投资34.45亿元；“开工一批”项目13个，总投资82.23亿元；“投产一批”项目11个，总投资37.6亿元。
      围绕洛阳石化百万吨乙烯项目，园区和洛阳石化定期沟通对接，提前谋划制定了百万吨乙烯项目“产业、企业、园区、院所”四张图谱，“按图索骥”开展招商、学习和交流活动，为项目落地打好基础。</p>
      
      <h4>四、创新引领</h4>
      <p>目前，园区拥有高新技术企业28家，科技型中小企业56家，各类研发平台载体共115个，其中国家级工程技术研究中心1家，国家级实验室1家，省级以上创新平台载体28家，市级以上创新平台载体85家，初步形成了新型研发机构支撑、龙头企业引领带动、科技型企业集聚发展、创新创业氛围浓厚的良好态势。2021年7月，北岸园区成功创建省级高新技术产业开发区。</p>
      <h4>五、基础设施</h4>
      <p>园区累计完成基础设施投资82.8亿元，建成道路53公里、供水管网38公里、排水管网98公里、燃气管网37公里、110kv变电站3座、220kv变电站2座、2×350MW天然气热电联供电厂1座、污水处理厂4座、中水回用厂1座。二广高速、208国道、洛济快速、洛焦快速穿境而过，310国道、连霍高速、郑州机场、洛阳机场近在咫尺。</p>
      <h4>六、优化服务</h4>
      <p>近年来,园区先后出台了《实施创新驱动发展战略鼓励科技创新奖励办法》《加快推进洛阳国家自主创新示范区吉利片区建设的若干意见》《招商引资工作推进机制》等支持政策。始终秉承现代管理理念和市场化手段，打造精简高效的管理体系和服务体系,全力营造优质高效营商环境。2021年，以“万人助万企”活动为契机,建立了企业家座谈会机制，定期召集企业家面对面交流，谈政策、谈产业、谈问题，不断提升服务准度和深度。面对疫情防控常态化,突出线上和线下相结合，不定期举办线上和线下对接会、招聘会,丰富服务形式，线上和线下发放“服务企业明白卡”，不断拉近企业距离，打通“最后一公里”。</p>
      <h4>七、安全生产 </h4>
      <p>园区牢固树立安全发展理念，按照《化工园区综合评价导则》( GB /T39217—2020)要求，成立专门安全管理机构，致力提升园区安全生产督导水平和企业安全生产双重预防机制运行水平，推动实现双重预防机制全覆盖。成立由36名专家组成的安全生产专家库，开展安全生产结对帮扶活动，促进企业安全管理水平整体提升。建设园区安全生产监测预警平台，通过物联网技术强化园区危险源安全监测和预警。常态化开展安全生产对话谈心、隐患排查、专家讲座等工作，帮助企业提升安全常识、知识、意识，形成安全共识。2019年、2020年通过化工园区安全风险评级，2021年8月列入河南省化工园区第二批名单。
      下一步，吉利华阳产业集聚区紧紧围绕“建强副中心、形成增长极”，以“一基地四园区”和“洛阳高新一孟津一孟州一济源”科创走廊为载体，坚持“项目为王”，全力推进百万吨乙烯、中硅高科、黎明院等重大项目，力争2021年营业收入达到800亿元、2023年达到1000亿元、2025年达到1200亿。在提升产业集聚的同时，不断完善城市服务功能、促进产城融合，把集聚区建设成为一个绿色、安全、循环、智慧的产业社区。</p>
      <img src="../../assets/huayang/huayang1.png" alt="" class="imgs">
    </div>
  </div>
</template>
<script>
import { WeGetAppletInfo } from "@/api/index";
export default {
  data() {
    return {
      active: 4,
      appInfo: {},
    };
  },
  mounted() {
    this.getAppInfo();
  },
  methods: {
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.content {
  padding-bottom: 30px;
}
.content .title{
  text-align: center;
}
.content h4{
  padding: 0px 15px;
  margin: 8px 0px;
}
.content p {
  padding: 0px 15px;
  margin: 15px 0px;
  text-indent:2em;
  line-height:35px;
}
.imgs {
  width: 90%;
  margin: 0 5%;
}
</style>